// 文件名-尽量和模块页面文件名统一(方便查找)
import service from '@/utils/axios'

// 设备列表
export const deviceList = params => service({
    url: 'device/list',
    params
    // 将来外面可能传入params的值 {limit: 20}
})
// 设备电池
export const battery_detection = params => service({
    url: 'device/battery_detection',
    params
    // 将来外面可能传入params的值 {limit: 20}
})
