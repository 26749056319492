// api文件夹下 各个请求模块js, 都统一来到index.js再向外导出
import { indexData } from '@/api/home'
import { deviceList, battery_detection } from '@/api/BMS'
import { streetLightMapGroupList, streetLightMapDetectionList } from '@/api/streetLightMap'


export const indexDataAPI = indexData
export const deviceListAPI = deviceList
export const batteryAPI = battery_detection
export const streetLightMapGroupListAPI = streetLightMapGroupList
export const streetLightMapDetectionListAPI = streetLightMapDetectionList

