// 引入 axios
// 网络请求 - 二次封装
import axios from 'axios'
// axios.defaults.baseURL = 'https://hzxk.xujingkj.com/index.php/api/'

// 创建axios实例
const service = axios.create({
    baseURL: 'https://hzxk.xujingkj.com/index.php/api/', //URL地址   环境变量文件 .env.development
})

service.interceptors.response.use(response => {
    // axios默认加了一层data
    const { data } = response.data
      return data
  }, error => {
    return error
  })






export default service
