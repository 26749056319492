// 文件名-尽量和模块页面文件名统一(方便查找)
import service from '@/utils/axios'

// 分组列表
export const streetLightMapGroupList = params => service({
    url: 'index/mapGroupList',
    params
    // 将来外面可能传入params的值 {limit: 20}
})
// 设备列表
export const streetLightMapDetectionList = params => service({
    url: 'index/mapDeviceList',
    params
    // 将来外面可能传入params的值 {limit: 20}
})
